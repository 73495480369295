import { login, phoneLogin, getProfile, refreshToken } from '@/api/user'
import { setToken, setRefreshToken, removeToken, removeRefreshToken } from '@/utils/auth'

const state = {
  token: '',
  nickname: '',
  username: '',
  avatar: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, nickname) => {
    state.nickname = nickname
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  localLogin({ commit }, payload) {
    const { username, password } = payload
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(res => {
        const { data } = res
        commit('SET_TOKEN', data.token)
        setToken(data.token, data.expire)
        setRefreshToken(data.refresh_token, data.refresh_expire)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  localPhoneLogin({ commit }, payload) {
    const { phone, code } = payload
    return new Promise((resolve, reject) => {
      phoneLogin({ phone: phone.trim(), code: code }).then(res => {
        const { data } = res
        commit('SET_TOKEN', data.token)
        setToken(data.token, data.expire)
        setRefreshToken(data.refresh_token, data.refresh_expire)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getProfile().then(res => {
        const { nickname, username, avatar } = res.data.info
        commit('SET_NAME', nickname)
        commit('SET_USERNAME', username)
        commit('SET_AVATAR', avatar)
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateInfo({ commit }, payload) {
    const { nickname, avatar } = payload
    commit('SET_NAME', nickname)
    commit('SET_AVATAR', avatar)
  },
  logout({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      removeRefreshToken()
      resolve()
    })
  },
  localRefreshToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { hasRefreshToken } = payload
      refreshToken({ refresh_token: hasRefreshToken }).then(res => {
        const { token, expire } = res.data
        commit('SET_TOKEN', token)
        setToken(token, expire)
        resolve()
      }).catch(error => {
        removeRefreshToken()
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
