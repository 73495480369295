<template>
  <div>
    <Navbar />
    <section class="container mx-auto md:px-4 px-2">
      <router-view />
    </section>
    <Footer />
  </div>
</template>

<script>

import './components/Navbar.vue'
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Footer,
  }
}
</script>