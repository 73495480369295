import request from '@/utils/request'
const baseURL = '/user'

/**
 * 用户登录
 * @param {*} data 
 * @returns 
 */
export function login(data) {
  return request({
    url: `${baseURL}/login`,
    method: 'post',
    data
  })
}
/**
 * 手机号登录
 * @param {*} data 
 * @returns 
 */
export function phoneLogin(data) {
  return request({
    url: `${baseURL}/phoneLogin`,
    method: 'post',
    data
  })
}
/**
 * 用户信息
 * @param {*} params 
 * @returns 
 */
export function getProfile(params) {
  return request({
    url: `${baseURL}/getProfile`,
    method: 'get',
    params
  })
}
/**
 * 刷新Token
 * @param {*} params 
 * @returns 
 */
export function refreshToken(params) {
  return request({
    url: `${baseURL}/refreshToken`,
    method: 'get',
    params
  })
}
/**
 * 获取验证码
 * @param {*} params 
 * @returns 
 */
 export function phoneCaptcha(params) {
  return request({
    url: `${baseURL}/phoneCaptcha`,
    method: 'get',
    params
  })
}
/**
 * 修改信息
 * @param {*} data 
 * @returns 
 */
 export function updateProfile(data) {
  return request({
    url: `${baseURL}/updateProfile`,
    method: 'post',
    data
  })
}
/**
 * 修改密码
 * @param {*} data 
 * @returns 
 */
 export function resetPassword(data) {
  return request({
    url: `${baseURL}/resetPassword`,
    method: 'post',
    data
  })
}