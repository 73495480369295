import Cookies from "js-cookie"
import settings from "../setting"

export function getToken() {
  return Cookies.get(settings.token)
}

export function setToken(token, expire) {
  let expireTime = new Date(new Date().getTime() + expire * 1000)
  return Cookies.set(settings.token, token, { expires: expireTime, domain: settings.domain })
}

export function removeToken() {
  return Cookies.remove(settings.token, { domain: settings.domain })
}

export function getRefreshToken() {
  return Cookies.get(settings.refreshToken)
}

export function removeRefreshToken() {
  return Cookies.remove(settings.refreshToken, { domain: settings.domain })
}

export function setRefreshToken(refreshToken, expire) {
  let expireTime = new Date(new Date().getTime() + expire * 1000)
  return Cookies.set(settings.refreshToken, refreshToken, { expires: expireTime, domain: settings.domain })
}