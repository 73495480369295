<!--
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-03-03 12:26:56
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2023-03-02 17:41:38
 * @FilePath: /grade-exam/src/layout/components/Navbar.vue
-->
<template>
  <div class="navbar-wrapper">
    <el-menu :default-active="$store.getters.activeIndex" class="md:flex justify-end items-center hidden" mode="horizontal" @select="handleSelect">
      <el-menu-item index="/logo" class="flex items-center ml-2" style="margin-right: auto;">
        <img style="height: 36px;" src="https://scratch.haimakid.net/static/logo.png" alt="logo">
      </el-menu-item>
      <el-menu-item index="/">首页</el-menu-item>
      <!-- <el-menu-item index="/course/list">等级课程</el-menu-item> -->
      <!-- <el-menu-item index="/exam/center">考试中心</el-menu-item> -->
      <el-menu-item index="/exam/record">考试记录</el-menu-item>
      <el-menu-item index="/mock/generate">模拟题</el-menu-item>
      <el-menu-item index="/wrong-set/list">错题本</el-menu-item>
      <!-- <el-menu-item index="/vip/center">开通会员</el-menu-item> -->
      <el-submenu index="0" v-if="name && avatar" class="mr-2" :style="{'max-width': '100px'}">
        <template slot="title">
          <el-avatar :src="avatar" icon="el-icon-user-solid"></el-avatar>
        </template>
        <el-menu-item><a href="https://haimakid.net/user/profile"><i class="el-icon-setting"></i>个人中心</a></el-menu-item>
        <el-menu-item index="/logout"><i class="el-icon-back"></i>注销</el-menu-item>
      </el-submenu>
      <el-menu-item v-else index="/login">登录</el-menu-item>
    </el-menu>
    
    <div class="flex justify-between items-center md:hidden py-2 px-3 bg-white" style="border-bottom: 1px solid #e6e6e6;">
      <i class="el-icon-s-unfold text-2xl text-gray-500" @click="openDrawer = true"></i>
      <span>海码考试中心</span>
      <el-avatar v-if="name && avatar" :src="avatar" icon="el-icon-user-solid"></el-avatar>
      <span v-else @click="$router.push('/login')">登录</span>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="openDrawer"
      direction="ltr"
      size="50%"
      :with-header="false">
      <el-menu :default-active="$store.getters.activeIndex" @select="handleSelect" class="mt-4">
        <el-menu-item>
          <img class="ml-2" src="https://scratch.haimakid.net/static/logo.png" alt="logo">
        </el-menu-item>
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/course/list">等级课程</el-menu-item>
        <!-- <el-menu-item index="/exam/center">考试中心</el-menu-item> -->
        <el-menu-item index="/exam/record">考试记录</el-menu-item>
        <el-menu-item index="/mock/generate">模拟题</el-menu-item>
        <el-menu-item index="/wrong-set/list">错题本</el-menu-item>
        <el-menu-item index="/ucenter/info">个人中心</el-menu-item>
        <el-menu-item index="/logout">注销</el-menu-item>
        <!-- <el-menu-item index="/vip/center">开通 VIP</el-menu-item> -->
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      openDrawer: false,
      drawerSize: '50%'
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'name'
    ])
  },
  methods: {
    handleSelect(key) {
      if (key === '/logo') {
        window.location.href = 'https://haimakid.net'
        return false
      }
      if (key === '/logout') {
        this.$store.dispatch('user/logout').then(() => { 
          this.$message({
            type: 'success',
            message: '注销成功',
            duration: 2000
          })
          this.$router.push('/login')
        })
      } else {
        this.$router.push(key)
      }

    }
  }
}
</script>

<style>
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
  min-width: 100px;
}
</style>