import settings from "@/setting"

const defaultTitle = settings.title || '海码考试中心'

export default function getPageTitle(title) {
  if (title) {
    return `${title} - ${defaultTitle}`
  } else {
    return defaultTitle
  }
}