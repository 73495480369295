<template>
  <div class="footer-wrapper bg-gray-100">
    <div class="container mx-auto">
      <div class="flex flex-wrap px-6 pt-8">
        <ul class="md:w-1/4 w-1/2 text-left mb-2 text-gray-400">
          <li class="text-xl font-bold mb-3 text-gray-500">关于我们</li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer"><el-icon class="el-icon-phone-outline mr-3"></el-icon>13876301230</li>
          <!-- <li class="pb-2 hover:text-gray-500 cursor-pointer flex"><el-icon class="el-icon-mouse mr-3"></el-icon>海南佳达科技有限公司</li> -->
          <li class="pb-2 hover:text-gray-500 cursor-pointer flex"><el-icon class="el-icon-office-building mr-3"></el-icon>海南省海口市琼山区龙昆南路<br>97-1号乾坤华源15F</li>
        </ul>
        <ul class="md:w-1/4 w-1/2 text-left mb-2 text-gray-400">
          <li class="text-xl font-bold mb-3 text-gray-500">电子学会</li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer">
            <a href="https://www.cie.org.cn/" target="_blank"><el-icon class="el-icon-link mr-3"></el-icon>中国电子学会</a>
          </li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer">
            <a href="http://px.cie.org.cn/" target="_blank"><el-icon class="el-icon-link mr-3"></el-icon>师资培训</a>
          </li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer">
            <a href="https://www.qceit.org.cn/" target="_blank"><el-icon class="el-icon-link mr-3"></el-icon>考评中心</a>
          </li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer">
            <a href="http://www.hainanie.net/" target="_blank"><el-icon class="el-icon-link mr-3"></el-icon>海南省电子学会</a>
          </li>
        </ul>
        <ul class="md:w-1/4 w-1/2 text-left mb-2 text-gray-400">
          <li class="text-xl font-bold mb-3 text-gray-500">海码编程</li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer">
            <a href="https://haimakid.net" target="_blank"><el-icon class="el-icon-link mr-3"></el-icon>编程社区</a>
          </li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer">
            <a href="https://edu.haimakid.net" target="_blank"><el-icon class="el-icon-link mr-3"></el-icon>海码学堂</a>
          </li>
          <li class="pb-2 hover:text-gray-500 cursor-pointer">
            <a href="https://match.haimakid.net" target="_blank"><el-icon class="el-icon-link mr-3"></el-icon>赛事中心</a>
          </li>
        </ul>
        <ul class="md:w-1/4 w-1/2 text-left mb-2 text-gray-400">
          <li class="text-xl font-bold mb-3 text-gray-500">微信公众号</li>
          <li class="pb-2">
            <el-avatar shape="square" :size="100" :src="'https://haimakid.net/static/front/images/community/wechat-qr.jpg'"></el-avatar>
          </li>
          <li class="pb-2">
            打开微信扫码<br>获取更多信息
          </li>
        </ul>
      </div>
      <div class="py-4">
        <p>海码编程 © 2022 All right reserved <a href="https://beian.miit.gov.cn/" class="text-blue-400" target="_blank">琼ICP备12002319号</a></p>
      </div>
      <el-backtop :right="20"></el-backtop>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>