const state = {
  activeIndex: '/'
}

const mutations = {
  SET_ACTIVE_INDEX: (state, index) => {
    state.activeIndex = index
  },
}

const actions = {
  setActiveIndex({ commit }, payload) {
    commit('SET_ACTIVE_INDEX', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}