/*
 * @Author: black9 lcl904624834@live.com
 * @Date: 2021-11-16 09:50:54
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-10-11 12:47:55
 * @FilePath: /grade-exam/src/utils/request.js
 */
import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
import { getToken, setToken, getRefreshToken, removeToken, removeRefreshToken } from './auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true, // send cookies when croess-domain requests
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    let token = getToken()
    if (token) {
      config.headers['Auth-Token'] = token
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  async response => {
    const res = response.data
    if (res.code !== 1) {
      if (res.code === 407 || res.code === 408 || res.code === 429) {
        const backToURL = encodeURIComponent(router.currentRoute.fullPath)
        if (res.code === 429) {
          removeToken()
          removeRefreshToken()
          router.push(`/login?redirect=${backToURL}`)
          return Promise.reject(new Error(res.msg || 'Error'))
        }
        // 检测是否存在refresh_token
        const refreshToken = getRefreshToken()
        if (refreshToken) {
          // 刷新token
          try {
            const refreshRes = await axios({
              url: `${process.env.VUE_APP_BASE_API}/user/refreshToken`,
              method: 'get',
              params: {
                'refresh_token': refreshToken
              }
            })
            if (refreshRes.data.code === 1) {
              setToken(refreshRes.data.data.token, refreshRes.data.data.expire)
              return service(response.config)
            } else {
              Message({
                message: refreshRes.data.msg || 'Error',
                type: 'error',
                duration: 3000
              })
              removeRefreshToken()
              router.push(`/login?redirect=${backToURL}`)
              return Promise.reject(new Error(res.msg || 'Error'))
            }
          } catch (error) {
            console.log(error)
            router.push(`/login?redirect=${backToURL}`)
            return Promise.reject(error.message || 'Error')
          }
        }
      }
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3000
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err:' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 3000
    })
    return Promise.reject(error)
  }
)

export default service