import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/pages/home.vue'),
        name: 'Home',
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/404',
    component: () => import('@/views/pages/404.vue'),
    meta: {
      title: '404 Not Found'
    }
  },
  {
    path: '/course',
    component: Layout,
    redirect: '/course/list',
    children: [
      {
        path: 'list',
        component: () => import('@/views/course/list.vue'),
        meta: { title: '等级课程' }
      },
      {
        path: 'detail',
        component: () => import('@/views/course/detail.vue'),
        meta: { title: '课程详情' }
      }
    ]
  },
  {
    path: '/exam',
    component: Layout,
    redirect: '/exam/list',
    children: [
      {
        path: 'center',
        component: () => import('@/views/exam/list.vue'),
        name: 'ExamCenter',
        meta: { title: '考试中心' }
      },
      {
        path: 'record',
        component: () => import('@/views/exam/record.vue'),
        name: 'ExamRecord',
        meta: { title: '考试记录' }
      },
      {
        path: 'need-laptop',
        component: () => import('@/views/exam/need-laptop.vue'),
        name: 'NeedLaptop',
        meta: { title: '考试设备提示' }
      },
      
    ]
  },
  {
    path: '/exam/detail',
    component: () => import('@/views/exam/detail.vue'),
    name: 'Detail',
    meta: { title: '试卷详情' }
  },
  {
    path: '/exam/analysis',
    component: () => import('@/views/exam/analysis.vue'),
    name: 'ExamAnalysis',
    meta: { title: '答案解析' }
  },
  {
    path: '/mock',
    component: Layout,
    redirect: '/mock/generate',
    children: [
      {
        path: 'generate',
        component: () => import('@/views/mock/generate.vue'),
        name: 'MockGenerate',
        meta: { title: '模拟题' }
      }
    ]
  },
  {
    path: '/wrong-set',
    component: Layout,
    redirect: '/wrong-set/list',
    children: [
      {
        path: 'list',
        component: () => import('@/views/wrong-set/list.vue'),
        name: 'WrongSetList',
        meta: { title: '错题本' }
      },
      {
        path: 'detail',
        component: () => import('@/views/wrong-set/detail.vue'),
        name: 'WrongSetDetail',
        meta: { title: '错题详情' }
      }
    ]
  },
  {
    path: '/ucenter',
    component: Layout,
    redirect: '/ucenter/info',
    children: [
      {
        path: 'info',
        component: () => import('@/views/ucenter/info.vue'),
        name: 'UcenterInfo',
        meta: { title: '个人信息' }
      }
    ]
  },
  {
    path: '/vip',
    component: Layout,
    redirect: '/vip/center',
    children: [
      {
        path: 'center',
        component: () => import('@/views/vip/center.vue'),
        name: 'VIPCenter',
        meta: { title: '会员中心' }
      }
    ]
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
